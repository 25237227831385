import axios from "axios";
import { refreshTokenApi, revokeTokenApi } from "../../services/api/Auth";

export const deleteToken = () => {
  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
  localStorage.removeItem("email");
};

const refreshToken = () =>
  new Promise((resolve, reject) => {
    refreshTokenApi()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });

export const initAxios = () => {
  axios.interceptors.request.use((config) => {
    config.baseURL = `${process.env.REACT_APP_BASE_URL}`;
    config.withCredentials = true;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const res = await refreshToken();
            if (res.jwtToken) {
              localStorage.setItem("token", res.jwtToken);
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${res.jwtToken}`;
            }
            return axios(originalConfig);
          } catch (_error) {
            deleteToken();
            return Promise.reject(
              _error.response ? _error.response.data : _error
            );
          }
        } else if (err.response.status === 403) {
          deleteToken();
          return Promise.reject(err.response.data);
        }
      }
      return Promise.reject(err);
    }
  );
};

export const Axios = axios;
