import { Axios } from "../utils/api/axiosInterceptor";

export const fetchWithTokenOnSetProgress = async (
  method,
  pathUrl,
  data,
  { onSetProgress }
) => {
  try {
    // localStorage.getItem("token")
    const token = localStorage.getItem("token");

    if (typeof method === "string" && typeof pathUrl === "string") {
      let methodType = method.toUpperCase();
      const result = await Axios({
        method: methodType,
        url: `${process.env.REACT_APP_BASE_URL}/${pathUrl}`,
        data: data,
        onUploadProgress: (progressEvent) => {
          if (progressEvent) {
            const percent = Math.round(
              (progressEvent.loaded / Number(progressEvent.total)) * 100
            );
            onSetProgress(Number(percent));
          }
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await result;
    } else {
      return {
        status: false,
        error: "method and path must type string.",
      };
    }
  } catch (error) {
    if (error.message === "Network Error") {
      return {
        status: false,
        message: error.message,
      };
    } else {
      return {
        status: false,
        message: error.response.data.message,
      };
    }
  }
};

export const fetchWithToken = async (method, pathUrl, data) => {
  try {
    const token = localStorage.getItem("token");
    if (typeof method === "string" && typeof pathUrl === "string") {
      let methodType = method.toUpperCase();
      const result = await Axios({
        method: methodType,
        url: `${process.env.REACT_APP_BASE_URL}/${pathUrl}`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await result;
    } else {
      return {
        status: false,
        error: "method and path must type string.",
      };
    }
  } catch (error) {
    if (error.message === "Network Error") {
      return {
        status: false,
        message: error.message,
      };
    } else {
      return {
        status: false,
        message: error.response.data.message,
      };
    }
  }
};

export const fetchWithOutAPI = async (method, pathUrl, data) => {
  if (typeof method === "string" && typeof pathUrl === "string") {
    let methodType = method.toUpperCase();
    try {
      const token = localStorage.getItem("token");

      const result = await Axios({
        method: methodType,
        url: `${process.env.REACT_APP_BASE_URL_WITHOUT_ADMIN}/${pathUrl}`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await result;
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location = "/user/login";
      } else {
        return {
          status: false,
          message: error.response.data.message,
        };
      }
    }
  } else {
    return {
      status: false,
      error: "method and path must type string.",
    };
  }
};

export const fetchWithoutToken = async (method, pathUrl, data) => {
  if (typeof method === "string" && typeof pathUrl === "string") {
    let methodType = method.toUpperCase();
    try {
      const result = await Axios({
        method: methodType,
        url: `${process.env.REACT_APP_BASE_URL}/${pathUrl}`,
        data: data,
      });
      return await result;
    } catch (error) {
      const errors = {
        data: {
          status: false,
          message: error.response.data.message,
          statusCode: error.response.status,
        },
      };
      return errors;
    }
  } else {
    return {
      status: false,
      error: "method and path must type string.",
    };
  }
};

export const fetchWithTokenFile = async (
  method,
  pathUrl,
  data,
  { onSetProgress }
) => {
  try {
    // localStorage.getItem("token")
    const token = localStorage.getItem("token");

    if (typeof method === "string" && typeof pathUrl === "string") {
      let methodType = method.toUpperCase();
      const result = await Axios({
        method: methodType,
        url: `${process.env.REACT_APP_BASE_URL_FILE}/${pathUrl}`,
        data: data,
        onUploadProgress: (progressEvent) => {
          if (progressEvent) {
            const percent = Math.round(
              (progressEvent.loaded / Number(progressEvent.total)) * 100
            );
            onSetProgress(Number(percent));
          }
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await result;
    } else {
      return {
        status: false,
        error: "method and path must type string.",
      };
    }
  } catch (error) {
    if (error.message === "Network Error") {
      return {
        status: false,
        message: error.message,
      };
    } else {
      return {
        status: false,
        message: error.response.data.message,
      };
    }
  }
};
