import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "helpers/Firebase";
import { Auth, getProfileWithEmail } from "helpers/Auth";
import { adminRoot, currentUser, loginRoot } from "constants/defaultValues";
import { setCurrentUser } from "helpers/Utils";
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_PROFILE,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from "./actions";

export function* watchLoginUser() {
  const token = localStorage.getItem("token");

  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
export function* watchGetProfile() {
  const token = localStorage.getItem("token");

  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_PROFILE, loginWithEmail);
}

//  ======= firebase login ==========

// const loginWithEmailPasswordAsync = async (email, password) => {
//   return await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((error) => error);
// }
//  ======= firebase login ==========

const loginWithEmailPasswordAsync = async (email, password) => {
  const token = localStorage.getItem("token");

  const response = await Auth({ email, password });
  return response;
};

function* loginWithEmailPassword({ payload }) {
  const token = localStorage.getItem("token");

  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.status) {
      const item = {
        uid: loginUser.data.id,
        username: loginUser.data.username,
        email: loginUser.data.email,
        token: loginUser.data.jwtToken,
        avatar: loginUser.data.avatar,
        roleUser: loginUser.data.role,
        address: loginUser.data.address,
        ...currentUser,
      };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

const loginWithEmailAsync = async (email) => {
  const token = localStorage.getItem("token");

  const response = await getProfileWithEmail(email);
  return response;
};

function* loginWithEmail({ payload }) {
  const { email, token, history } = payload;

  try {
    const loginUser = yield call(loginWithEmailAsync, email);
    if (loginUser.status) {
      const item = {
        uid: loginUser.data.id,
        username: loginUser.data.username,
        email: loginUser.data.email,
        token: loginUser.data.jwtToken,
        avatar: loginUser.data.avatar,
        roleUser: loginUser.data.role,
        address: loginUser.data.address,
        ...currentUser,
      };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      // history.push(adminRoot);
    } else {
      if (loginUser.message == "token expire") {
        // history.push(loginRoot);
      }
      // yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    history.push(loginRoot);
    // yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  const token = localStorage.getItem("token");

  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  localStorage.clear;
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  // await auth
  //   .signOut()
  //   .then((user) => user)
  //   .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProfile),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
