import axios from 'axios'

const revokeTokenApi = async () => {
  return axios({
    method: 'POST',
    url: '/auth/revoke-token',
    baseURL: process.env.REACT_APP_BASE_URL_WITHOUT_ADMIN,
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })
}

const refreshTokenApi = async () => {
  return axios({
    method: 'POST',
    url: '/auth/refresh-token',
    baseURL: process.env.REACT_APP_BASE_URL_WITHOUT_ADMIN,
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })
}

export { revokeTokenApi, refreshTokenApi }
