import { fetchWithToken, fetchWithoutToken } from './useAxios'

export const Auth = async ({ email, password }) => {
  let data = {
    email: email,
    password: password,
  }
  const res = await fetchWithoutToken('put', 'auth/authentication', { data })
  return res.data
}

export const getProfileWithEmail = async (email) => {
  const res = await fetchWithToken('get', `profile/${email}`)
  return res.data
}
